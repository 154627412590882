<template>
	<div class='content'>
		<!-- <h4 class='location'>{{location}}</h4> -->
		<baidu-map :center="center" @ready="handler" :scroll-wheel-zoom="true" :zoom="zoom">
			<bm-view class="map">
				<!-- <bm-marker style="width: 100px;height: 100px;" :position="locPoint" :dragging="true" @click="infoWindowOpen">
					
				</bm-marker> -->
				
				
			</bm-view>
			<!-- <bm-info-window style="width: 100px;height: 50px;display: block;z-index: 99999;position: absolute;" title="hhshs" :position="{ lng: lng, lat: lat }" :show="show"
				@close="infoWindowClose(marker)" @open="infoWindowOpen(marker)">
				{{location}}
			</bm-info-window> -->
		</baidu-map>
	</div>
</template>

<script>
	import {
		BmlMarkerClusterer
	} from 'vue-baidu-map'
	export default {
		components: {
			BmlMarkerClusterer
		},
		name: "simple-map",
		props: {
			lng: '',
			lat: '',
			location: ''
		},
		data: () => ({
			center: {
				lng: '',
				lat: ''
			},
			show: false,
			zoom: 15,
			keyword: '',
			centerPoint: {
				lng: 113.282202,
				lat: 23.13771
			},
			locPoint: {

			},
		}),
		created() {

		},
		methods: {
			infoWindowClose() {
				this.show = false
			},
			infoWindowOpen() {
				this.show = true
			},
			handler({
				BMap,
				map
			}) {

				var point = new BMap.Point(this.lng, this.lat);
				this.locPoint = {
					lng: this.lng,
					lat: this.lat
				}
				map.centerAndZoom(point, 15);
				var marker = new BMap.Marker(point); // 创建标注
				map.addOverlay(marker); // 将标注添加到地图中
				// var circle = new BMap.Circle(point, 200, {
				// 	strokeColor: "#007aff",
				// 	strokeWeight: 1,
				// 	strokeOpacity: 10,
				// 	fillOpacity: 0.2,
				// 	Color: "#007aff",
				// 	fillColor: "#007aff"
				// });
				// map.addOverlay(circle);
				var marker = new BMap.Marker(point);
				// 将标注添加到地图中
				map.addOverlay(marker);
				// 创建信息窗口对象
				var opts = {
				  width : 200,     // 信息窗口宽度
				  height: 50,     // 信息窗口高度
				  title :'' // 信息窗口标题
				};
				var infoWindow = new BMap.InfoWindow(this.location, opts); // 创建信息窗口对象
				// 打开信息窗口
				 map.openInfoWindow(infoWindow, point); // 打开信息窗口
				marker.addEventListener("click", function(){
				  map.openInfoWindow(infoWindow, point); // 打开信息窗口
				});
			},

		}
	};
</script>

<style scoped>
	.map {
		width: 100%;
		height: 350px;
		position: relative;
	}


	.bm-view {
		margin-left: 0%;
		width: 100%;
		margin-top: 10px;
		color: #999999;
		height: 40px;
	}
	.location{
		margin-bottom: 20px;
	}
</style>