<template>
    <div id="container" style="height:500px;"></div>
</template>

<script>
var thisMap
var infoWindow
export default {
    components:{
          
    },
    props: {
        mapData:{
			type:Object,
			default: () => {
                return {};
            }
		}
    },
    data: () => ({
        mapKey:'IBMBZ-DDN3Q-VQA5G-BCPMU-BN7JK-HGFO6',
        center: { lng: 115.86631, lat: 28.695285},
        zoom: 17,
    }),
    watch: {
        mapData:{
            handler(newValue){
                this.center = newValue
                this.setMapCenter()
                this.getMarker()
            }
        },
        deep:true
    },
    created() {
        this.center = this.mapData
		this.loadScript()
	},
    mounted(){
        
    },
    methods: {
        //实例组件
        loadScript() {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://map.qq.com/api/gljs?v=1.exp&key="+this.mapKey+"&callback=initMap";
            document.body.appendChild(script);

            let that = this
            setTimeout(function(){
                that.initMap()
            },300)
        },
        initMap() {
            let that = this
            //定义地图中心点坐标
            var center = new TMap.LatLng(that.center.lat,that.center.lng)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            thisMap = new TMap.Map(document.getElementById('container'), {
                center: center,//设置地图中心点坐标
                zoom:that.zoom,   //设置地图缩放级别
            });
            thisMap.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE);//移除腾讯地图比例尺
            thisMap.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);//移除腾讯地图旋转控件
            thisMap.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);//移除腾讯地图缩放控件
            
            //设置标记点
            that.getMarker()
        },
        setMapCenter(){
            thisMap.setCenter(new TMap.LatLng(Number(this.center.lat),Number(this.center.lng)));
        },

        getMarker(){
            let that = this
            //点位标记
            // var marker = new TMap.MultiMarker({
            //     map: thisMap,
            //     styles: {
            //         marker: new TMap.MarkerStyle({
            //             width: 20, // 样式宽
            //             height: 30, // 样式高
            //             anchor: { x: 10, y: 30 }, // 描点位置
            //         }),
            //     },
            //     geometries: [
            //         {
            //             position: new TMap.LatLng(Number(that.center.lat),Number(that.center.lng)),
            //             id: 'marker',
            //         },
            //     ],
            // });
            
            if(infoWindow){
                infoWindow.close();
            }
            //信息标记
            infoWindow = new TMap.InfoWindow({
                map: thisMap, 
                enableCustom: true,
                position: new TMap.LatLng(that.center.lat,that.center.lng), //设置信息框位置
                offset: { y: -59, x: 0 },
                content: '<div class="map_info_main"><div class="map_info_main_content">'+that.center.address+'</div><span class="cancle"></span></div>'
            });
        },
    }
};
</script>

<style>

    .map_info_main {
        display:flex;
        align-items: center;
        margin:50px auto;
        position:absolute;
        min-width:50px;
        max-width:500px;
        height:49px;
        background: #ffffff;
        color: #333333;
        border-radius:5px;
        box-shadow: 0px 3px 8px grey;
    }

    .map_info_main_content{
        margin:5px 10px 5px 10px;
        font-size:13px;
    }
    
    .map_info_main span.cancle{
        width:0;
        height:0;
        font-size:0;
        overflow:hidden;
        position:absolute;
        border-width:10px; 
        border-style:solid dashed dashed; 
        border-color:#ffffff transparent transparent;
        left:50%; 
        bottom:-16px;
        transform: translate(-50%, 0);
    }
    
</style>
